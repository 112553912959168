const RegisterFormManager = {};

RegisterFormManager.init = function () {
  const inputEmail = $('#js-f_email');
  const inputUsername = $('#js-inputUsername');
  const checkDuplicateAddress = $('#js-duplicateAddress');

  inputEmail.on('input', function () {
    inputUsername.val($(this).val());
  });

  checkDuplicateAddress.on('click', function () {
    if ($(this).is(':checked')) {
      RegisterFormManager.duplicateFacturationValues();
    } else {
      RegisterFormManager.resetAddressValues();
    }
  });
};

RegisterFormManager.duplicateFacturationValues = function () {
  //$("ul[data-slide='" + current +"']");
  $('#js-l_company').val($('#js-f_company').val());
  $('#js-l_firstname').val($('#js-f_firstname').val());
  $('#js-l_lastname').val($('#js-f_lastname').val());
  $('#js-l_phone').val($('#js-f_phone').val());
  $('#js-l_address').val($('#js-f_address').val());
  $('#js-l_email').val($('#js-f_email').val());
  $('#js-l_postcode').val($('#js-f_postcode').val());
  $('#js-l_city').val($('#js-f_city').val());
  $('#js-l_state').val($('#js-f_state').val());
  $('#js-l_country').val($('#js-f_country').val());
};

RegisterFormManager.resetAddressValues = function () {
  $('#js-l_societe').val('');
  $('#js-l_prenom').val('');
  $('#js-l_nom').val('');
  $('#js-l_address').val('');
  $('#js-l_email').val('');
  $('#js-l_state').val('');
  $('#js-l_country').val('');
};

export default RegisterFormManager;
