const CommandeFormManager = {};

CommandeFormManager.state = {
  totalCount: 0,
  totalPrice: 0,
};

CommandeFormManager.init = function () {
  this.commandeItems = $('.js-commandeItem');
  this.sizeInputs = $('.js-sizeInput');
  this.submitButton = $('#js-submitButton');
  this.totalCommandeItems = $('#js-totalCommandeItems');
  this.totalCommandePrice = $('#js-totalCommandePrice');
  this.csrf_token = document.querySelector('[data-csrf]').dataset.csrf;
  this.confirmCheckout = $('#js-confirmCheckout');
  this.confirmCheckoutYes = $('#js-confirmCheckoutYes');
  this.confirmCheckoutNo = $('#js-confirmCheckoutNo');

  this.setGrandTotals();
  this.initFormEvents();
};

CommandeFormManager.debounce = function (fun, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fun.apply(this, args);
    }, timeout);
  };
};

CommandeFormManager.initFormEvents = function () {
  this.sizeInputs.on(
    'change',
    CommandeFormManager.debounce(function (e) {
      CommandeFormManager.disableSumbitButton();

      // Init Variables
      const $input = $(e.target);
      const item_sku = $input.data('item-sku');
      const item_id = $input.data('item-id');
      const itemPrice = $input.data('item-price');
      const $siblings = $(`.js-sizeInput[data-item-id=${item_id}]`);
      const item_sizes = { T0: 0, T1: 0, T2: 0, T3: 0, T4: 0 };
      //Calculate new count
      $siblings.each((i, element) => {
        const size = $(element).data('size');
        const quantity = Number($(element).val());
        item_sizes[size] = Number(quantity);
      });
      const newTotalCount = Object.values(item_sizes).reduce((a, b) => a + b, 0);

      // Calculate new Price
      const newTotalPrice = Number(newTotalCount) * Number(itemPrice);
      const newTotalPriceFormatted = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(newTotalPrice);

      // Update display
      $(`.js-itemTotalCount[data-item-id=${item_id}]`).html(newTotalCount);
      $(`.js-itemTotalPrice[data-item-id=${item_id}]`).html(newTotalPriceFormatted);

      // Change row style if we have have zero quantity
      const $tr = $(`.js-commandeItem[data-item-id=${item_id}]`);
      newTotalCount === 0 ? $tr.addClass('opacity-50') : $tr.removeClass('opacity-50');

      // Update Grand Totals
      CommandeFormManager.setGrandTotals();
      CommandeFormManager.updateGrandTotalsDisplay();

      // Update Database
      const csrf_token = CommandeFormManager.csrf_token;
      const item = {
        item_sku,
        item_id,
        item_sizes,
      };
      const data = {
        csrf_token,
        item,
      };

      $.post(
        '/orders/process/update',
        data,
        function (response) {
          //console.log(response);
          CommandeFormManager.enableSumbitButton();
        },
        'json'
      );
    }, 300)
  );

  this.submitButton.on('click', function () {
    CommandeFormManager.checkout();
    CommandeFormManager.disableSumbitButton();
    return false; // prevent default form submit behavior
  });
};

CommandeFormManager.setGrandTotals = function () {
  let totalCount = 0;
  let totalPrice = 0;
  this.sizeInputs.each((i, element) => {
    const $input = $(element);
    const count = $input.val();
    const price = $input.data('item-price');
    totalCount += Number(count);
    totalPrice += Number(count) * Number(price);
  });
  this.state.totalCount = totalCount;
  this.state.totalPrice = totalPrice;
};

CommandeFormManager.updateGrandTotalsDisplay = function () {
  this.totalCommandeItems.html(this.state.totalCount);
  this.totalCommandePrice.html(
    new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
      this.state.totalPrice
    )
  );
};

CommandeFormManager.enableSumbitButton = function () {
  this.submitButton.disabled = false;
  this.submitButton.removeClass('opacity-50');
};

CommandeFormManager.disableSumbitButton = function () {
  this.submitButton.disabled = true;
  this.submitButton.addClass('opacity-50');
};

CommandeFormManager.checkout = function () {
  // Set again the totals in case we missed an Ajax call
  this.setGrandTotals();
  // fetch database to get total item count and total item price
  // compare with local total item count and total item price
  // if the response is a success, they are the same, we can checkout
  const data = {
    csrf_token: CommandeFormManager.csrf_token,
    total_count: this.state.totalCount,
    total_price: this.state.totalPrice,
  };
  $.post(
    '/orders/process/preflight',
    data,
    function (response) {
      // console.log(response);
      if (response.success) {
        CommandeFormManager.showConfirm();
      } else {
        window.location.reload;
      }
    },
    'json'
  );
};

CommandeFormManager.showConfirm = function () {
  CommandeFormManager.confirmCheckout.removeClass('hidden');
  CommandeFormManager.confirmCheckoutNo.on('click', function () {
    CommandeFormManager.confirmCheckout.addClass('hidden');
  });
  CommandeFormManager.confirmCheckoutYes.on('click', function () {
    CommandeFormManager.confirmCheckoutYes.prop('disabled', true);
    window.location.href = '/orders/process/checkout';
  });
};

export default CommandeFormManager;
