const ItemSelectorManager = {};

ItemSelectorManager.initialState = {
  selectedType: '',
  selectedRef: '',
  selectedColor: '',
  sizeQuantities: { T0: 0, T1: 0, T2: 0, T3: 0, T4: 0 },
};

ItemSelectorManager.init = function () {
  this.state = { ...this.initialState };
  this.selectType = $('#js-selectType');
  this.selectRef = $('#js-selectRef');
  this.selectRefOptions = $('#js-selectRef option');
  this.selectColor = $('#js-selectColor');
  this.selectColorOptions = $('#js-selectColor option');
  this.selectSizes = $('#js-selectSizes');
  this.selectSizeInputs = $('#js-selectSizes input');
  this.submitButton = $('#js-addToCommande');
  this.csrf = this.submitButton.data('csrf');

  this.initSelectType();
  this.initSelectRef();
  this.initSelectColor();
  this.initSelectSizes();
  this.initSubmitButton();

  this.initFormEvents();
};

ItemSelectorManager.initSelectType = function () {
  this.state.selectedType = this.initialState.selectedType;
  this.selectType.val('');
};

ItemSelectorManager.initSelectRef = function () {
  this.state.selectedRef = this.initialState.selectedRef;
  this.selectRef.addClass('hidden');
  this.selectRef.val('');
  const type = this.state.selectedType;
  if (type) {
    this.selectRef.removeClass('hidden');
  }
  this.initSelectRefOptions();
};

ItemSelectorManager.initSelectRefOptions = function () {
  const type = this.state.selectedType;
  this.selectRefOptions.prop('selected', false);
  this.selectRefOptions.addClass('hidden');
  if (type) {
    this.selectRefOptions.filter(`[data-type=${type}]`).removeClass('hidden');
  }
};

ItemSelectorManager.initSelectColor = function () {
  const ref = this.state.selectedRef;
  this.state.selectedColor = this.initialState.selectedColor;
  this.selectColor.addClass('hidden');
  this.selectColor.val('');
  if (ref) {
    this.selectColor.removeClass('hidden');
  }
  this.initSelectColorOptions();
};

ItemSelectorManager.initSelectColorOptions = function () {
  const ref = this.state.selectedRef;
  this.selectColorOptions.prop('selected', false);
  this.selectColorOptions.addClass('hidden');
  if (ref) {
    this.selectColorOptions.filter(`[data-ref=${ref}]`).removeClass('hidden');
  }
};

ItemSelectorManager.initSelectSizes = function () {
  const color = this.state.selectedColor;
  this.state.sizeQuantities = { ...this.initialState.sizeQuantities };
  this.selectSizeInputs.each((i, element) => {
    $(element).val(0);
  });
  this.selectSizes.addClass('hidden');
  if (color) {
    this.selectSizes.removeClass('hidden');
  }
  this.initSelectSizeInputs();
};

ItemSelectorManager.initSelectSizeInputs = function () {
  const color = this.state.selectedColor;
  const selectSizeInputDivs = this.selectSizeInputs.parent();
  selectSizeInputDivs.addClass('hidden');
  if (color) {
    const availableSizeInputDivs = selectSizeInputDivs.filter(`[data-sku=${color}]`);
    const availableSizeInputs = this.selectSizeInputs.filter(`[data-sku=${color}]`);
    availableSizeInputDivs.removeClass('hidden');
    availableSizeInputs.prop('disabled', false);
  }
};

ItemSelectorManager.initSubmitButton = function () {
  const item = this.getItemToAddToCommande();

  if (item) {
    this.submitButton.removeClass('hidden');
    this.submitButton.prop('disabled', false);
  } else {
    this.submitButton.prop('disabled', true);
    this.submitButton.addClass('hidden');
  }
};

ItemSelectorManager.initFormEvents = function () {
  // Listen to type change
  this.selectType.on('change', (e) => {
    const type = $(e.target).val();
    ItemSelectorManager.state.selectedType = type;
    ItemSelectorManager.initSelectRef();
    ItemSelectorManager.initSelectColor();
    ItemSelectorManager.initSelectSizes();
    ItemSelectorManager.initSubmitButton();
  });

  // Listen to Ref change
  this.selectRef.on('change', (e) => {
    const ref = $(e.target).val();
    ItemSelectorManager.state.selectedRef = ref;
    ItemSelectorManager.initSelectColor();
    ItemSelectorManager.initSelectSizes();
    ItemSelectorManager.initSubmitButton();
  });

  // Listen to Color change
  this.selectColor.on('change', (e) => {
    const color = $(e.target).val();
    const itemId = $(e.target).find(`option[value="${color}"]`).data('item-id');
    ItemSelectorManager.state.selectedColor = color;
    ItemSelectorManager.state.itemId = itemId;
    ItemSelectorManager.initSelectSizes();
    ItemSelectorManager.initSubmitButton();
  });

  // Listen to Sizes selection
  this.selectSizeInputs.on('change', (e) => {
    const $input = $(e.target);
    const size = $input.data('size');
    const value = $input.val();
    ItemSelectorManager.state.sizeQuantities[size] = Number(value);
    ItemSelectorManager.initSubmitButton();
  });

  // Listen to submit
  this.submitButton.on('click', function () {
    const csrf_token = document.querySelector('[data-csrf]').dataset.csrf;
    const item = ItemSelectorManager.getItemToAddToCommande();
    const data = {
      csrf_token,
      item,
    };

    $.post(
      '/orders/process/add-item',
      data,
      function (response) {
        //console.log(response);
        window.location.href = '/orders/new';
      },
      'json'
    );
  });
};

ItemSelectorManager.getItemToAddToCommande = function () {
  const item_sku = this.state.selectedColor;
  const item_id = this.state.itemId;
  const item_sizes = this.state.sizeQuantities;

  const items_count = Object.values(item_sizes).reduce((a, b) => a + b, 0);

  if (item_id && item_sku && items_count > 0) {
    return {
      item_sku,
      item_id,
      item_sizes,
    };
  }
  return false;
};

export default ItemSelectorManager;
