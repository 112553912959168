import CommandeFormManager from './CommandeFormManager';
import ItemSelectorManager from './ItemSelectorManager';
import RegisterFormManager from './RegisterFormManager';

const InterfaceManager = {
  init: function () {
    this.initItemSelectorManager();
    this.initRegisterFormManager();
    this.initCommandeFormManager();
  },
  initRegisterFormManager: function () {
    const el = $('.js-registerForm');
    if (el.length > 0) {
      RegisterFormManager.init();
    }
  },
  initItemSelectorManager: function () {
    const el = $('#js-itemSelector');
    if (el.length > 0) {
      ItemSelectorManager.init();
    }
  },
  initCommandeFormManager: function () {
    const el = $('#js-commandeForm');
    if (el.length > 0) {
      CommandeFormManager.init();
    }
  },
};

$(document).ready(function () {
  InterfaceManager.init();
});
